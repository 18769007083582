import React from 'react';
import { Link } from 'gatsby';

import { Container, SEO } from 'layout';

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Страница не найдена" />
    <h1>404: Страница не найдена</h1>
    <Link to="/">Вернуться на главную</Link>
  </Container>
);

export default NotFoundPage;
